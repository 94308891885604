@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.linear_gradientbix {
  position: relative;
  padding: 2rem 2rem;
  border-radius: 0.4rem;
  background: rgb(59, 87, 158);
  background: linear-gradient(
    215deg,
    rgba(59, 87, 158, 1) 0%,
    rgba(93, 108, 147, 1) 100%
  );
}
.iconsbox {
  position: absolute;
  top: 2rem;
  right: 2.5rem;
  padding: 1rem 0px;
  opacity: 0.5;
  transform: scale(2);
  transition: transform 0.5s ease;
}
.iconsbox:hover {
  transform: scale(3);
}


.main_header {
  height: 100vh;
  width: 100%;
}

.first_div {
  width: 90%;
  margin: 20px auto;
  height: 90vh;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
  display: grid;
  place-items: center;
}

.flex_Box {
  width: 80%;
  margin: auto;
  height: 80%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
}

.left_Side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_Side img {
  height: 350px;
  max-width: 100%;
}

.right_Side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right_Side div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right_Side div p {
  color: #333;
  font-family: Poppins-Bold;
  font-size: 24px;
  padding-bottom: 45px;
  text-align: center;
  width: 100%;
}

.right_Side div span {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  color: #666;
}

.login_input {
  background: #e6e6e6;
  color: #666;
  font-family: Poppins-Medium;
  font-size: 15px;
  height: 50px;
  padding: 0 30px 0 68px;
  border-radius: 25px;
  display: block;
  width: 100%;
  border: none;
  outline: none;
}

.login_submit {
  border: none;
  outline: none;
  opacity: 1;
  background-color: rgb(87, 184, 70);
  text-align: center;
  background: #57b846;
  border-radius: 25px;
  color: #fff;
  display: flex;
  font-family: Montserrat-Bold;
  font-size: 15px;
  height: 50px;
  justify-content: center;
  line-height: 1.5;
  align-items: center;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 768px) {
  .first_div {
    height: 100vh;
    padding: 20px;
  }

  .flex_Box {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .left_Side {
    width: 100%;
    text-align: center;
  }

  .left_Side img {
    max-width: 70%;
    height: auto;
  }

  .right_Side {
    width: 100%;
  }

  .login_input,
  .login_submit {
    width: 100%;
  }
}


/*loading page style */

.loading_section {
  width: 100%;
  height: 80vh;
  display: grid;
  place-items: center;
}

.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #e50914;
  border-color: #e50914 transparent #e50914 transparent;
  animation: loading 1.2s infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

/* pdf download button hide @print */
@media print {
  #printButton, #copyUrlButton {
    display: none !important;
  }
}


/* footer by */
#footerScroll {
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 15px;
  background-color: #1976d2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  transition: transform 0.3s ease;
  padding: 0px 7px;
  flex-wrap: wrap;
  font-size: 0.6rem;
}

/* Media query for small devices (up to 600px wide) */
@media screen and (max-width: 600px) {
  #footerScroll {
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    bottom: -3px;
    align-items: center;
  }
  #footerScroll span {
    margin-bottom: 4px;
    font-size: 0.7rem;
  }
  #footerScroll span:nth-last-child(2) {
    display: none;
  }
}
