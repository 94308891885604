.valid,.invalid {
  font-weight: 300;
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
}
.valid {
  color: green;
}

.invalid {
  color: red;
}

.user-client-table {
  width: 100%;
  table-layout: fixed;
}

.user-actions {
  width: auto;
}
